html {
    background-color: rgba(0, 99, 190, 0.05);
}

.header {
    margin-bottom: 15px;
}

.mainContainer {
    margin-bottom: 15px;
}

.container {
    margin: 15px auto 10px auto;
    padding: 30px;
    max-width: 600px;
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    border-radius: 5px;
}

@media (max-width: 400px) {
    .container {
        margin: 10px auto 10px auto;
        padding: 15px;
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.half {
    width: 50%;
}

.title {
    font-size: 18px;
}
